import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { Button } from 'app/components/Common/Button'
import { Line } from 'app/components/Common/Line'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Item, Props as ItemProps } from 'app/components/OffersList/Item'
import { useVocabularyData } from 'app/utils/vocabulary'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useState } from 'react'

export interface Props {
  languageCode: string
  offersPageURL?: string
  slides: ItemProps[]
  subtitle?: string
  title?: string
}

export const OffersSlider = memo(function OffersSlider({
  languageCode,
  offersPageURL,
  slides,
  subtitle,
  title,
}: Props) {
  if (slides.length < 1) {
    return null
  }

  const [_currentSlide, setCurrentSlide] = useState(0)

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: slides.length > 2 ? true : false,
    slides: {
      perView: 2,
      spacing: 60,
    },
    animationEnded(s) {
      setCurrentSlide(s.track.details.rel)
    },
    breakpoints: {
      '(max-width: 1023px)': {
        loop: true,
        slides: {
          perView: 2.05,
          spacing: 12,
        },
      },
      '(max-width: 767px)': {
        loop: true,
        slides: {
          perView: 1.05,
          spacing: 12,
        },
      },
    },
  })

  return (
    <Container>
      {title ? (
        <Claim>
          <Line />
          {title}
        </Claim>
      ) : (
        <Title>{useVocabularyData('other-offers-title', languageCode)}</Title>
      )}

      {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}

      <Slider ref={sliderRef}>
        {slides.map((item, index) => (
          <Item key={index} className="keen-slider__slide" {...item} />
        ))}
      </Slider>

      {offersPageURL ? (
        <ButtonWrap>
          <CTA
            label={useVocabularyData('all-offers', languageCode)}
            URL={offersPageURL}
            variant="outline"
          />
        </ButtonWrap>
      ) : null}

      {slides.length > 2 ? (
        <Arrows row>
          <Arrow
            onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()}
          />
          <Arrow
            direction="R"
            onClick={(e) => e.stopPropagation() || instanceRef.current?.next()}
          />
        </Arrows>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  margin-top: 10.75rem;
  padding: 9rem 10vw;
  position: relative;
  &:before {
    content: '';
    width: 66.666%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.secondaryLight};
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  @media (max-width: 1023px) {
    background: ${({ theme }) => theme.colors.variants.secondaryDark};
    margin-top: 8.5rem;
    padding: 3.75rem 1.3125rem;
    &:before {
      display: none;
    }
  }
`

const Claim = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.75rem;

  @media (max-width: 1023px) {
    font-size: 2.1875rem;
    line-height: 2.8125rem;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 2.5rem;
`

const Subtitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  letter-spacing: 0.0938rem;
  line-height: 1.1875rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  margin-top: 0.75rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    margin-top: 0.375rem;
  }
`

const Slider = styled.div`
  display: flex;
  margin-top: 4.75rem;
  overflow: hidden;
  &[data-keen-slider-moves] {
    a {
      pointer-events: none;
    }
  }

  @media (max-width: 1023px) {
    overflow: visible !important;
    margin-top: 2.5rem;
  }
`

const Arrows = styled(FlexBox)`
  position: absolute;
  top: 8rem;
  right: 10vw;
`

const ButtonWrap = styled.div`
  margin-top: 3.875rem;
  text-align: center;

  @media (max-width: 1023px) {
    margin-top: 2.5rem;
  }
`

const CTA = styled(Button)``
